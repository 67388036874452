/**
 * To update the list of browser polyfills, please see polyfill.base.ts
 * Note: polyfill.base.ts is included via the tsconfig.base.json
 */

import 'polyfill.base';

/***************************************************************************************************
 * APPLICATION IMPORTS
 */

/**
 * Date, currency, decimal and percent pipes.
 * Needed for: All but Chrome, Firefox, Edge, IE11 and Safari 10
 */
import 'intl'; // Run `npm install --save intl`.
/**
 * Need to import at least one locale-data with intl.
 */
import 'intl/locale-data/jsonp/en';

// CSS Vars
import cssVars from 'css-vars-ponyfill';

cssVars({
  watch: true,
  shadowDOM: true,
});

// https://www.npmjs.com/package/srcdoc-polyfill
// Used for rendering the email preview in Marketing Automation
import 'srcdoc-polyfill';
